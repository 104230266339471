<template>
    <div class="page-wrapper">
        <section class="hero_block">
            <div class="data">
                <h1 class="title" data-inview="fadeInUp" data-delay="200">
                    <span v-html="contentIsLoaded ? content.heroSections.titre : ''"></span>
                </h1>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="contentIsLoaded ? content.heroSections.texte : ''"></span>
                </p>
                <a :href="contentIsLoaded ? content.heroSections.boutonUrl[$route.meta.lang] : ''" class="cta regular-text" data-inview="fadeInUp" data-delay="400"
                    ><span v-html="contentIsLoaded ? content.heroSections.bouton.customText : ''"></span></a
                >
                <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
            </div>
            <div class="hero_img" data-inview="slideLeft" data-delay="200">
                <img :src="contentIsLoaded ? content.heroSections.image.url : ''" :alt="contentIsLoaded ? content.heroSections.image.titre : ''" />
            </div>
            <img src="@/assets/img/birds-hero.svg" alt="birds" class="birds_hero" />
        </section>

        <section class="text_block" v-if="pageIsLoaded && content.entete1 && content.entete1.titre && content.id != 471">
            <h2 class="title medium" data-inview="fadeInUp" data-delay="200">
                <span v-html="content.entete1.titre"></span>
            </h2>
            <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                <span v-html="content.entete1.texte"></span>
            </p>
            <a :href="content.entete1.boutonUrl[$route.meta.lang]" class="cta regular-text" data-inview="fadeInUp" data-delay="400"
                ><span v-html="content.entete1.bouton.customText"></span></a
            >
        </section>

        <section class="questions_block" v-if="pageIsLoaded && content.blockInformations1 && content.blockInformations1.titre">
            <div class="data">
                <h2 class="title medium" data-inview="fadeInUp" data-delay="200">
                    <span v-html="content.blockInformations1.titre"></span>
                </h2>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.blockInformations1.texte"></span>
                </p>
                <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
            </div>
            <div class="questions" v-if="content.choixReponses && content.choixReponses.length">
                <div class="question" data-inview="fadeInUp" :data-delay="(2 + i) + '00'" v-for="(item, i) in content.choixReponses" :key="i">
                    <p class="regular-text">
                        <span v-html="item.texte"></span>
                    </p>
                    <div class="checkbox">
                        <div class="check">
                            <p class="regular-text">Oui</p>
                            <input type="checkbox" />
                        </div>
                        <div class="check">
                            <p class="regular-text">Non</p>
                            <input type="checkbox" />
                        </div>
                    </div>
                </div>
            </div>
            <img
                src="@/assets/img/icon-deux-maisons-vert.svg"
                alt="Maison Péladeau icône"
                class="maisons"
            />
        </section>

        <section class="goals_wrap" v-if="pageIsLoaded && content.entete2 && content.entete2.titre && content.thumbs1 && content.thumbs1.length">
            <div class="content">
                <h2 class="title medium" data-inview="fadeInUp" data-delay="200">
                    <span v-html="content.entete2.titre"></span>
                </h2>
                <p class="regular-text max_width" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.entete2.texte"></span>
                </p>
                <div class="goals">
                    <h3 class="title small" data-inview="fadeIn" data-delay="200">
                        {{ content.titre1 }}
                    </h3>
                    <div class="goal" data-inview="fadeInUp" :data-delay="(2 + i) + '00'" v-for="(item, i) in content.thumbs1" :key="i">
                        <div class="img">
                            <img :src="item.image.url" :alt="item.image.titre" />
                        </div>
                        <p class="regular-text">{{ item.texte }}</p>
                    </div>
                </div>
                <a :href="content.boutonUrl[$route.meta.lang]" class="cta regular-text">{{ content.bouton.customText }}</a>
            </div>
        </section>

        <section class="programme_scroll" v-if="pageIsLoaded && content.thumbs2 && content.thumbs2.length">
            <div class="title_wrap">
                <h2 class="title medium">{{ content.titre2 }}</h2>
                <img src="@/assets/img/scroll.svg" alt="Scroll" />
            </div>
            <div class="scroll">
                <div class="programme" data-inview="fadeInUp" data-delay="200" v-for="(item, i) in content.thumbs2" :key="i">
                    <div class="img">
                        <img
                            :src="item.image.url"
                            :alt="item.image.titre"
                        />
                    </div>
                    <p class="regular-text"><span v-html="item.texte"></span></p>
                </div>
            </div>
        </section>

        <section class="timeline_wrap" v-if="pageIsLoaded && content.cartes1 && content.cartes1.length">
            <h3 class="title medium">{{ content.titre3 }}</h3>
            <div class="timeline">
                <div class="circle" v-for="(item, i) in content.cartes1" :key="i"></div>
            </div>
            <div class="steps">
                <div class="step" data-inview="fadeInUp" data-delay="200" v-for="(item, i) in content.cartes1" :key="i">
                    <img src="@/assets/img/triangle.svg" alt="triangle" class="triangle" />
                    <p class="regular-text">
                       <span v-html="item.texte"></span>
                    </p>
                </div>
            </div>
        </section>

        <section
            class="text_img" :class="{ 'switch': i % 2 }"
            v-for="(item, i) in splitImageTexte"
            :key="i"
        >
            <div class="data">
                <h3 class="title medium" data-inview="fadeInUp" data-delay="200">
                    <span v-html="item.titre"></span>
                </h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="item.texte"></span>
                </p>
                <a
                    :href="item.boutonUrl[$route.meta.lang]"
                    target="_blank"
                    class="cta regular-text"
                    data-inview="fadeInUp"
                    data-delay="400"
                    >{{ item.bouton.customText }}</a
                >
            </div>
            <div class="img" :data-inview="i % 2 ? 'slideRight' : 'slideLeft'" data-delay="200">
                <img :src="item.image.url" :alt="item.image.titre" />
            </div>
            <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
        </section>

        <section class="text_block ender" v-if="pageIsLoaded && content.entete1 && content.entete1.titre && content.id == 471">
            <h2 class="title medium" data-inview="fadeInUp" data-delay="200">
                <span v-html="content.entete1.titre"></span>
            </h2>
            <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                <span v-html="content.entete1.texte"></span>
            </p>
            <a :href="content.entete1.boutonUrl[$route.meta.lang]" class="cta regular-text" data-inview="fadeInUp" data-delay="400"
                ><span v-html="content.entete1.bouton.customText"></span></a
            >
        </section>

        <section class="testimonials" data-inview="fadeInUp" data-delay="200" v-if="pageIsLoaded && content.temoignages && content.temoignages.length">
            <img src="@/assets/img/quote.svg" alt="quote" class="quote" data-inview="fadeInUp" data-delay="300" />
            <div class="carousel-wrap">
                <img src="@/assets/img/texture-2.svg" alt="texture" class="texture" />
                <div class="main-carousel" data-inview="fadeInUp" data-delay="600">
                    <div class="carousel-cell" v-for="(item, i) in content.temoignages" :key="i">
                        <div class="left">
                            <img
                                :src="item.image.url"
                                data-inview="slideRight"
                                data-delay="200"
                                :alt="item.image.titre"
                            />
                        </div>
                        <div class="right">
                            <h4 class="title small">
                                <span v-html="item.titre"></span>
                            </h4>
                            <p class="regular-text">
                                <span v-html="item.texte"></span>
                            </p>
                            <p class="title name">
                                <span v-html="item.sousTitre"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="houses_wrap" v-if="pageIsLoaded">
            <div class="title_wrap">
                <img
                    src="@/assets/img/icon-deux-maisons.svg"
                    alt="Icon maisons péladeau"
                    data-inview="fadeInUp"
                    data-delay="200"
                />
                <h3 class="title medium" data-inview="fadeInUp" data-delay="300">
                    Deux centres pour vous accueillir
                </h3>
            </div>
            <router-link
                :to="item.page.jsonUrl"
                class="house"
                data-inview="fadeInUp"
                data-delay="200"
                v-for="(item, i) in globals.centresEntry.data" :key="i"
            >
                <div class="img">
                    <img
                        :src="item.page.imageSimple.url"
                        :alt="item.page.imageSimple.titre"
                    />
                </div>
                <div class="data">
                    <p class="regular-text">{{ item.page.titre1 }}</p>
                    <h4 class="title small">{{ item.page.titre }}</h4>
                    <svg
                        fill="none"
                        class="plus"
                        viewBox="0 0 60 60"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="30" cy="30" r="30" />
                        <path
                            d="m30 20c-.7268 0-1.3158.589-1.3158 1.3158v7.3684h-7.3684c-.7268 0-1.3158.589-1.3158 1.3158s.589 1.3158 1.3158 1.3158h7.3684v7.3684c0 .7268.589 1.3158 1.3158 1.3158s1.3158-.589 1.3158-1.3158v-7.3684h7.3684c.7268 0 1.3158-.589 1.3158-1.3158s-.589-1.3158-1.3158-1.3158h-7.3684v-7.3684c0-.7268-.589-1.3158-1.3158-1.3158z"
                        />
                    </svg>
                </div>
            </router-link>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'
import Flickity from 'flickity'

export default {
    name: 'Service',

    data() {
        return {
            teamCarousel: null,
        }
    },

    methods: {
        toogleNavBarStatus() {
            const st = document.documentElement.scrollTop

            if (!document.querySelector('.hero_block')) {
                return
            }

            if (st >= 75) {
                document.querySelector('.hero_block').classList.add('remove_margin_top')
            } else {
                document.querySelector('.hero_block').classList.remove('remove_margin_top')
            }
        },
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.teamCarousel = new Flickity(document.querySelector('.main-carousel'), {
                            wrapAround: true,
                            imagesLoaded: true,
                            prevNextButtons: true,
                            pageDots: false,
                        })

                        this.toogleNavBarStatus()
                        window.addEventListener('scroll', () => {
                            this.toogleNavBarStatus()
                        })
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        splitImageTexte() {
            if (!this.contentIsLoaded) {
                return []
            }
            if (!this.content.splitImageTexte) {
                return []
            }
            return this.content.splitImageTexte
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>
